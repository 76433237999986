import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import { computed } from 'vue';
import type { CSSProperties } from 'vue';

export type Keys<T> = [path: string, propName: keyof T][];

export default function computedStyleFactory<T>(
  param: object | string | undefined,
  getValue: (value: string) => any,
  keysOrPropName: Keys<T> | keyof T,
): () => T {
  return () => {
    const style: T = {} as T;

    if (param) {
      if (typeof param === 'object') {
        for (const [path, propName] of keysOrPropName as Keys<T>) {
          const value = get(param, path);

          if (!isUndefined(value)) {
            style[propName] = getValue(value);
          }
        }
      } else {
        style[keysOrPropName as keyof T] = getValue(param);
      }
    }

    return style;
  };
}

export interface BaseMargin {
  xs?: number;
  lg?: number;
  md?: number;
}

export interface BasePadding {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export interface BaseContainerPadding {
  lg?: BasePadding;
  md?: BasePadding;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export interface BaseContainerStyleProps<MarginKeys extends string = 'title'> {
  style?: {
    padding?: BaseContainerPadding;
    margin?: Record<MarginKeys, BaseMargin>;
    background?: string;
  };
}

export interface BaseContainerStyle extends CSSProperties {
  '--padding-top'?: string;
  '--padding-bottom'?: string;
  '--padding-top-lg'?: string;
  '--padding-bottom-lg'?: string;
}

export interface BaseTitleStyle extends CSSProperties {
  '--title-margin'?: string;
  '--title-margin-lg'?: string;
}

interface BaseWrapperStyle extends CSSProperties {
  '--background'?: string;
}

export const useBaseContainerStyle = (props: { params: BaseContainerStyleProps }) => {
  return computed(
    computedStyleFactory<BaseContainerStyle>(props.params?.style?.padding, (val) => `${val}px`, [
      ['top', '--padding-top'],
      ['left', '--padding-left'],
      ['bottom', '--padding-bottom'],
      ['right', '--padding-right'],
      ['lg.top', '--padding-top-lg'],
      ['lg.bottom', '--padding-bottom-lg'],
      ['lg.left', '--padding-left-lg'],
      ['lg.right', '--padding-right-lg'],
    ]),
  );
};

export const useBaseTitleStyle = (props: { params: BaseContainerStyleProps }) => {
  return computed(
    computedStyleFactory<BaseTitleStyle>(props.params?.style?.margin?.title, (val) => `${val}px`, [
      ['xs', '--title-margin'],
      ['lg', '--title-margin-lg'],
    ]),
  );
};

export const useBaseWrapperStyle = (props: { params: BaseContainerStyleProps }) => {
  return computed(
    computedStyleFactory<BaseWrapperStyle>(
      props.params?.style?.background,
      (val) => val,
      '--background',
    ),
  );
};
