import { ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

const useOnElementInViewport = (callback: () => void, once = false, delay = 0) => {
  const target = ref(null);
  const targetIsVisible = ref(false);
  let visibilityTimeout: ReturnType<typeof setTimeout> | null = null;

  const { stop } = useIntersectionObserver(target, ([{ isIntersecting }]) => {
    targetIsVisible.value = isIntersecting;

    if (isIntersecting && callback) {
      visibilityTimeout = setTimeout(() => {
        callback(); // вызываем колбэк после задержки (даже если delay = 0)

        if (once) {
          stop(); // Останавливаем наблюдение, если нужно только одно срабатывание
        }
      }, delay); // Если delay = 0, setTimeout выполнит callback в микротаске
    } else if (visibilityTimeout) {
      clearTimeout(visibilityTimeout);
      visibilityTimeout = null;
    }
  });

  return {
    target,
    targetIsVisible,
  };
};

export default useOnElementInViewport;
