<template>
  <div ref="target" class="promo-item" :style="wrapperStyle">
    <div class="promo-item__container" :style="[containerStyle, sizeStyle]">
      <!--   Если один див, возникает ситуация гидрейшон мисматча на планшетах   -->
      <!--   Кроме того мобильного айдишника может не быть   -->
      <div
        v-show="isDesktop || !props.promo.externalMobileId"
        :id="`buzzplayer-${props.promo.externalDesktopId}`"
        :key="`mobile-${key}`"
        style="width: 100%"
      />
      <div
        v-show="!isDesktop && props.promo.externalMobileId"
        :id="`buzzplayer-${props.promo.externalMobileId}`"
        :key="`desktop-${key}`"
        style="width: 100%"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import type { PagePromo } from '@/types/dto/page-promo';
import { useBaseContainerStyle, useBaseWrapperStyle } from '@/services/computedStyleFactory';
import { useStore } from 'vuex';
import { cdekConsoleLog } from '@/utils/console-wrapper';
import useOnElementInViewport from '@/composables/use-on-element-in-viewport';
import { sendParams } from '@/plugins/ym-plugin';

const emit = defineEmits(['no-ad', 'ready-to-show']);
const props = defineProps<{
  promo: PagePromo;
}>();

const key = ref(0);
const store = useStore();
const containerStyle = useBaseContainerStyle(props.promo);
const wrapperStyle = useBaseWrapperStyle(props.promo);
const isDataSent = ref(false);
const hasAd = ref(false);

const isDesktop = computed(() => {
  return store.getters['resize/screen'].desktop;
});

const currentId = computed(() => {
  if (isDesktop.value) {
    return props.promo.externalDesktopId;
  }

  return props.promo.externalMobileId || props.promo.externalDesktopId;
});

const sendData = () => {
  if (!hasAd.value || isDataSent.value) {
    return;
  }

  sendParams({ placementId: currentId.value });
  isDataSent.value = true;
};

const { target, targetIsVisible } = useOnElementInViewport(sendData, true);

const sizeStyle = computed(() => {
  const style: Record<string, string> = { width: '100%' };

  if (!props.promo.params) {
    return style;
  }

  const { height, maxWidth } = props.promo.params;

  if (maxWidth === false) {
    style.maxWidth = 'unset';
  }

  if (height) {
    if (height.mobile) {
      style['--height'] = `${height.mobile}px`;
    }

    if (height.desktop) {
      style['--desktop-height'] = `${height.desktop}px`;
    }
  }

  return style;
});

const addElement = () => {
  hasAd.value = false;

  const slot = document.querySelector(`#buzzplayer-${currentId.value}`) as any;

  (window.buzzoolaAdUnits = window.buzzoolaAdUnits || []).push({
    slot,

    placementId: currentId.value,

    sizes: props.promo.params?.sizes,
    // Ветка не дотестирована, тестить будем на проде))))
    // Логи для дебага
    onFetch(adUnit, player) {
      cdekConsoleLog('Got ad response', adUnit, player);
    },
    onReady(adUnit, player) {
      hasAd.value = true;
      cdekConsoleLog('Ready to show', adUnit, player);
      emit('ready-to-show', props.promo);

      if (targetIsVisible.value) {
        sendData();
      }
    },
    onError(error, adUnit, player) {
      emit('no-ad', props.promo);

      cdekConsoleLog('Something went wrong', error, adUnit, player);
    },
    onNoad(adUnit, player) {
      emit('no-ad', props.promo);
      cdekConsoleLog('No ad', adUnit, player);
    },
    onDestroy(adUnit, player) {
      hasAd.value = false;

      emit('no-ad', props.promo);
      cdekConsoleLog('Destroyed', adUnit, player);
    },
  });
};

onMounted(() => {
  addElement();
});

watch(currentId, async () => {
  key.value += 1;
  await nextTick();
  addElement();
});
</script>

<style scoped lang="scss">
.promo-item {
  --background: transparent;

  background: var(--background);

  &__container {
    @include admin-container(0, 0);

    --padding-left: 0;
    --padding-right: 0;
    --padding-left-lg: 0;
    --padding-right-lg: 0;
    --height: unset;
    --desktop-height: unset;

    height: var(--height);
    padding-left: var(--padding-left);
    padding-right: var(--padding-right);
    margin-inline: 0;

    @include media-min-lg {
      height: var(--desktop-height);
      padding-left: var(--padding-left-lg);
      padding-right: var(--padding-right-lg);
    }
  }
}
</style>
